import { Icon, IconGenWithLabel, instagram, twitter, youtube } from 'components/Icon'
import React, { FC, useEffect, useState } from 'react'
import styles from 'components/Footer_LEGACY/Footer.module.scss'
import { amClosedFooterSection, amExpandedFooterSection } from 'events/amplitude'
import { A } from 'components/basic/A'
import { headerToID } from './utils'
import { trackClickSocialMedia } from 'events/index'
import { generateID } from 'components/Phantom/_sections/ScrollSequenceStatic/utils'

export interface SocialLinkProps {
	href: string
	icon: IconGenWithLabel
	onClick: () => void
}

export const SocialLink: FC<SocialLinkProps> = (props) => (
	<li>
		<a
			href={props.href}
			onClick={props.onClick}
			aria-label={`${props.icon.label} social media`}
		>
			<Icon icon={props.icon} />
		</a>
	</li>
)

export const getSocialLinks = () => (
	<>
		<SocialLink
			href={'https://www.youtube.com/@eight_sleep'}
			icon={youtube}
			onClick={() => trackClickSocialMedia('Youtube')}
		/>
		<SocialLink
			href={'https://www.instagram.com/eightsleep'}
			icon={instagram}
			onClick={() => trackClickSocialMedia('Instagram')}
		/>
		<SocialLink
			href={'https://www.twitter.com/eightsleep'}
			icon={twitter}
			onClick={() => trackClickSocialMedia('Twitter')}
		/>
	</>
)

export interface FooterListItemProps {
	header: string
	sublistItems: { text: string; href: string; onClick?: () => void }[]
}

export const FooterListItem: FC<FooterListItemProps> = (props) => {
	const [expanded, setExpanded] = useState(false)
	const sublistRef = React.createRef<HTMLUListElement>()

	useEffect(() => {
		if (sublistRef.current) {
			if (expanded) {
				sublistRef.current.style.setProperty('--height', `${2.5 * props.sublistItems.length}rem`)
			} else {
				sublistRef.current.style.setProperty('--height', '0')
			}
		}
	}, [expanded])

	const id = headerToID(props.header)

	return (
		<li className={styles.list_item}>
			<div className={styles.expand_controls}>
				<input
					aria-controls={`${id}ul`}
					className={'vh'}
					type="checkbox"
					name={id}
					id={id}
					onChange={(e) => {
						if (e.target.checked) {
							amExpandedFooterSection(props.header)
						} else {
							amClosedFooterSection(props.header)
						}
						setExpanded(e.target.checked)
					}}
				/>
				<label
					htmlFor={id}
					className={styles.checkbox_label}
				>
					<h3>{props.header}</h3>
					<PlusMinusToggle minus={expanded} />
				</label>
			</div>

			<h3 className={styles.desktop_header}>{props.header}</h3>

			<ul
				ref={sublistRef}
				className={`${styles.sublist} ${expanded ? styles.sublist_expanded : ''}`}
				id={`${id}ul`}
			>
				{props.sublistItems.map((item) => (
					<li
						key={item.text}
						className={styles.sublist_item}
					>
						<A
							href={item.href}
							onClick={item.onClick}
							id={`footer-${generateID(item.text)}`}
						>
							{item.text}
						</A>
					</li>
				))}
			</ul>
		</li>
	)
}

export const PlusMinusToggle = (props: { minus: boolean }) => {
	return (
		<span className={styles.plus_minus_toggle}>
			<div
				className={styles.line}
				style={{ transform: props.minus ? '' : 'rotate(90deg)' }}
			/>
			<div className={styles.line} />
		</span>
	)
}
