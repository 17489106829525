import { FC, FormEvent, useRef, useState } from 'react'
import { CaptureLocation } from 'utils/events'
import { subscribeToKlaviyo } from 'eight_api/aws'
import styles from './EmailInput.module.scss'
import { arrow_right, check, Icon } from 'components/Icon'
import { trackSubscribeEmailAfterKlaviyoLambda } from 'events/index'

export interface EmailInputProps {
	location: CaptureLocation
	placeholder: string
	errorMessage: string
	className?: string
}
export const EmailInput: FC<EmailInputProps> = (props) => {
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const ref = useRef<HTMLInputElement>(null)

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault()
		if (!email) {
			ref.current.setCustomValidity(props.errorMessage)
			return
		}

		setLoading(true)

		if ((window as any)['CONFIG_TYPE'] === 'DEVELOPMENT' || window.location.hostname.includes('chromatic.com')) {
			await new Promise((r) => setTimeout(r, 1000))
			setSuccess(true)
			setLoading(false)
			return
		}

		const response = await subscribeToKlaviyo(email, props.location, { VIPACCESS: 1 })

		if (!response || response.error) {
			ref.current.setCustomValidity(props.errorMessage)
		} else {
			trackSubscribeEmailAfterKlaviyoLambda(email, props.location)
			setSuccess(true)
		}
		setLoading(false)
	}

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		setEmail(e.currentTarget.value)
		ref.current.setCustomValidity('')
	}

	if (success) return <div className={styles.success}>Check your inbox for your discount</div>

	return (
		<form
			onSubmit={handleSubmit}
			className={styles.form}
		>
			<input
				ref={ref}
				type="email"
				placeholder={props.placeholder}
				onChange={handleChange}
				value={email}
				disabled={loading}
				minLength={3}
				aria-label="Email"
			/>
			<button
				type={'submit'}
				disabled={loading}
				aria-label="Submit"
			>
				<span className={styles.icon}>{success ? <Icon icon={check} /> : <Icon icon={arrow_right} />}</span>
			</button>
		</form>
	)
}
