'use client'

import { CleanNav } from 'components/CleanNav'
import { NavBar } from 'components/NavBar/NavBar'
import { trackCartOpen } from 'events/index'
import { FC } from 'react'
import { useStore } from 'stores/StoreProvider'
import { useCartNumItems } from 'stores/cart'
import { useCurrentRegion, useSaleEndDate } from 'stores/settings'

interface Props {
	any?: string
}

export const HeaderConnected = () => {
	return <Header />
}

const HeaderComponent: FC<Props> = (props) => {
	const toggleCartOpen = useStore((state) => state.cart.toggleCartOpen)
	const numItems = useCartNumItems()
	const openCart = () => {
		trackCartOpen()
		toggleCartOpen()
	}
	const toggleLocalizeModal = useStore((state) => state.modal.toggleLocalizeModal)
	const setPaymentOption = useStore((state) => state.shop.setPaymentOption)

	const currentRegion = useCurrentRegion()
	const currentPage = useStore((state) => state.settings.currentPage)
	const endDate = useSaleEndDate()
	const settings = useStore((state) => state.settings.settings)
	const isVanity = useStore((state) => state.settings.isVanity)
	const navBarTransparency = useStore((state) => state.settings.navBarTransparency)

	const promoLink = useStore((state) => state.promo.promoBarLink)
	const activeTimer = settings.activeTimer
	const showTimer = useStore((state) => state.promo.showTimer)
	const promoMessage = useStore((state) => state.promo.promoBarTitle)

	let path = currentPage.split('?')[0].replace(`/${currentRegion}`, '')
	if (!path.endsWith('/')) {
		path = `${path}/`
	}
	if (!path.startsWith('/')) {
		path = `/${path}`
	}

	const isCoverLP = path === '/pod-cover/'
	const isProspecting = path === '/pod-prospecting/' || path === '/cooling-sleep/' || path === '/pod-compare/' || path === '/pod-prospecting-yt/'
	const isMember = ['/pod4-member/', '/product/pod4-insert', '/member-accessories/'].includes(path)

	const isShopPage = path.includes('product')
	const isSleepElixirPDP = path === '/product/sleep-elixir/'
	const isSleepElixirWaitlist = path === '/sleep-elixir-waitlist/'

	const handleLocalize = currentRegion === 'eu' && typeof window !== 'undefined'

	if (isSleepElixirWaitlist) {
		return (
			<CleanNav
				region={currentRegion}
				saleName={settings.saleName}
			/>
		)
	}

	return (
		<NavBar
			promo_bar_text={promoMessage}
			promo_bar_href={promoLink}
			promo_bar_date={endDate}
			region={currentRegion}
			cartClick={openCart}
			numberOfCartItems={numItems}
			ctaVisible={!isShopPage || isSleepElixirPDP}
			ctaLabel={isSleepElixirPDP || isSleepElixirWaitlist ? 'Shop the Pod' : 'Shop now'}
			activeTimer={activeTimer}
			showTimer={showTimer}
			excludeLinks={isMember || isProspecting}
			onlyLogo={isProspecting}
			ctaAnchorHref={isProspecting ? '#shop' : null}
			transparency={navBarTransparency}
			isCoverLPAB={isCoverLP}
			isVanity={isVanity}
			saleName={settings.saleName}
			onPickerClick={
				handleLocalize
					? () => {
							toggleLocalizeModal()
					  }
					: undefined
			}
			renderCountryPicker={isShopPage}
			setRental={() => {
				setPaymentOption('fragile')
			}}
		/>
	)
}

export const Header = HeaderComponent

export default Header
